import { merge } from "lodash";
import { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { Box, Card, Grid, Stack, alpha } from "@mui/material";
import { fNumber } from "../../../utils/formatNumber";
import { BaseOptionChart } from "../../charts";

function AppConversionRates(props) {
  const [refreshGraph, setRefreshGraph] = useState(false);
  const [mappedData, setMappedData] = useState([]);
  const [combinedMappedData, setCombinedMappData] = useState([]);

  useEffect(() => {
    if (mappedData.length > 0) {
      const sortedMappedData = [...mappedData].sort((a, b) => b.y[0] - a.y[0]);
      const seriesData = [
        {
          name: "Your Practice",
          data: sortedMappedData?.map((item) => ({
            x: item.x,
            y: item.y[0],
            fillColor: item.fillColor,
          })),
        },
        {
          name: "Filtered Practice",
          data: sortedMappedData?.map((item) => ({
            x: item.x,
            y: item.y[1],
            fillColor: item.strokeColor,
          })),
        },
      ];

      setCombinedMappData(seriesData);
      setRefreshGraph((prev) => !prev);
    }
  }, [mappedData]);

  useEffect(() => {
    const combineData = (data1, data2) => {
      const map = new Map();
      data1?.forEach((item) => {
        map.set(item.x, { ...item });
      });
      data2?.forEach((item) => {
        if (map.has(item.x)) {
          const existingItem = map.get(item.x);
          map.set(item.x, {
            ...existingItem,
            y: [existingItem.y, item.y],
          });
        } else {
          map.set(item.x, { ...item });
        }
      });

      return Array.from(map.values());
    };

    const combinedGreenData = combineData(
      props.ab?.green_prescribed?.length ? props.ab.green_prescribed : [],
      props.otherab?.othertypes_green?.length
        ? props.otherab.othertypes_green
        : []
    );

    const combinedRedData = combineData(
      props.ab?.red_prescribed?.length ? props.ab.red_prescribed : [],
      props.otherab?.othertypes_red?.length ? props.otherab.othertypes_red : []
    );

    const finalCombinedData = [...combinedGreenData, ...combinedRedData];

    setMappedData(finalCombinedData);
  }, [props.ab, props.otherab]);

  const chartOptionsResist = merge(BaseOptionChart(), {
    tooltip: {
      enabled: false,
      marker: { show: false },
      x: {
        show: true,
        formatter: undefined,
        title: {
          formatter: (seriesName) => seriesName,
        },
      },
      y: {
        formatter: (value, { series, seriesIndex }) => {
          let actualCount;
          if (series[seriesIndex]?.name === "Your Practice" && props.generalAB.total) {
            actualCount = ((value * props.generalAB.total) / 100).toFixed(0);
          } else if (series[seriesIndex]?.name === "Filtered Practice" && props.generalABcomparison.othertotal) {
            actualCount = ((value * props.generalABcomparison.othertotal) / 100).toFixed(0);
          }
          return `${fNumber(actualCount)} isolates`;
        },
        title: {
          formatter: (seriesName) => `Count of isolates showing resistance: `,
        },
      },
    },
    chart: {
      type: "bar",
      height: 350,
      stacked: false,
      stackType: "100%",
    },
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          position: "top",
        },
        barHeight: 20,
        borderRadiusApplication: "end",
      },
    },
    stroke: {
      show: false,
    },
    dataLabels: {
      formatter: function (value, { seriesIndex, dataPointIndex, w }) {
        return (value ? value.toFixed(0) : 0) + "%";
      },
      enabled: true,
      offsetX: 45,
      style: {
        colors: ["#708090"],
        fontWeight: "normal",
      },
      // offsetX: 50,
    },
    xaxis: {
      min: 0,
      max: 100,
      stepSize: 25,
      title: {
        text: "%",
        style: {
          color: "#22577A",
        },
      },
      labels: {
        show: true,
        style: {
          colors: "#22577A",
        },
      },
    },
    yaxis: {
      labels: {
        show: true,
        style: {
          colors: "#22577A",
        },
      },
    },
    fill: {
      opacity: [1, 0.5],
    },
    colors: [
      alpha("#26734D", 1),
      alpha("#26734D", 0.5),
    ],
    legend: {
      show: false,
      position: "bottom",
      horizontalAlign: "right",
      floating: true,
      markers: {
        width: 0,
        height: 0,
      },
    },
  });

  return (
    <Card sx={{ boxShadow: "none" }}>
      <Stack
        direction="row"
        justifyContent="space-evenly"
        alignItems="flex-end"
        spacing={6}
        mt={1}
      >
        <Grid container>
          <Grid item sm={12} md={6}>
            <Box sx={{ mx: 1, width: 800, height: 600 }} dir="ltr">
              <br />
              <ReactApexChart
                type="bar"
                series={combinedMappedData}
                options={chartOptionsResist}
                key={refreshGraph}
              />
            </Box>
          </Grid>
        </Grid>
      </Stack>
    </Card>
  );
}

import { connect } from "react-redux";
const mapState = (state) => ({
  generalAB: state.antibiotics.generalAB,
  ab: state.antibiotics.ab,
  otherab: state.antibioticscomparisons.abcomparison,
  generalABcomparison: state.antibioticscomparisons.generalABcomparison,
});

const mapDispatch = ({ antibiotics: { getGeneralAB } }) => ({
  getGeneralAB: () => getGeneralAB(),
});

export default connect(mapState, mapDispatch)(AppConversionRates);
