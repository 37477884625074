import * as Yup from "yup";
import { useState, useEffect } from "react";
import { useFormik, Form, FormikProvider } from "formik";
import { Checkbox, Snackbar, Typography } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import MuiAlert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Switch from "@mui/material/Switch";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { LoadingButton } from "@mui/lab";
import { Radio, FormControlLabel } from "@mui/material";
import Link from "@mui/material/Link";
import { connect } from "react-redux";
import { values } from "lodash";

function RegistrationDetailForm(props) {
  const navigate = useNavigate();
  const [isSnackbarOpen, setSnackbarOpen] = useState(false);
  const [hospitals, setHospitals] = useState([]);
  const [labs, setLabs] = useState([]);
  const [place, setPlace] = useState([]);
  const [healthareas, setHealthareas] = useState([]);

  useEffect(async () => {
    if (props.practice.hospitals && props.practice.hospitals !== undefined) {
      setHospitals(props.practice.hospitals);
    }
  }, [props.practice.hospitals]);

  useEffect(async () => {
    if (props.practice.places && props.practice.places !== undefined) {
      setPlace(props.practice.places);
    }
  }, [props.practice.places]);

  useEffect(async () => {
    if (props.practice.laboritires && props.practice.laboritires !== undefined) {
      setLabs(props.practice.laboritires);
    }
  }, [props.practice.laboritires]);

  useEffect(async () => {
    if (props.practice.healthareas && props.practice.healthareas !== undefined) {
      setHealthareas(props.practice.healthareas);
    }
  }, [props.practice.healthareas]);

  useEffect(async () => {
    await props.getHospitals();
    await props.getLaboratories();
    await props.getPlaces();
    await props.getHealthareas();
  }, []);


  const LoginSchema = Yup.object().shape({
    practiceName: Yup.string().required("*Mandatory"),
    contactperson: Yup.string().required("*Mandatory"),
    gpsnumber: Yup.string().required("*Mandatory"),
    nursesnumber: Yup.string().required("*Mandatory"),
    eircode: Yup.string().required("*Mandatory"),
    mainhospitalid: Yup.string().required("*Mandatory"),
    laboratoryid: Yup.string().required("*Mandatory"),
    manager: Yup.boolean().required("*Mandatory"),
    placeid: Yup.string().required("*Mandatory"),
    healthareasid: Yup.string().required("*Mandatory"),
    dispensingpractice: Yup.boolean().required("*Mandatory"),
    termsAccepted: Yup.boolean()
      .oneOf([true], "You must accept the terms and conditions")
      .required("*Mandatory"),
  });

  const formik = useFormik({
    initialValues: {
      email: props.email,
      contactperson: '',
      gpsnumber: '',
      nursesnumber: '',
      manager: false,
      eircode: '',
      mainhospitalid: null,
      secondhospitalid: null,
      placeid: null,
      laboratoryid: null,
      healthareasid: null,
      dispensingpractice: false,
      termsAccepted: false
    },

    validationSchema: LoginSchema,
    onSubmit: async (values) => {
      const responce = await props.setPractice(values);
      if (responce) {
        let payload = {
          email: values.email,
          check: "download"
        }
        await props.resendOTP(payload);
        navigate('/login');
      }
    },
  });

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <Stack direction="row" spacing={1}>
            <TextField
              fullWidth
              autoComplete="practiceName"
              type="text"
              label="Practice name"
              {...getFieldProps("practiceName")}
              error={Boolean(touched.practiceName && errors.practiceName)}
              helperText={touched.practiceName && errors.practiceName}
            />
            <TextField
              fullWidth
              autoComplete="contactperson"
              type="text"
              label="Practice lead for CARA "
              {...getFieldProps("contactperson")}
              error={Boolean(touched.contactperson && errors.contactperson)}
              helperText={touched.contactperson && errors.contactperson}
            />

            <Stack></Stack>
            <Switch {...getFieldProps("manager")} color="primary" />
            <Typography sx={{ color: "text.secondary", fontSize: 15 }}>
              Practice manager
            </Typography>
          </Stack>

          <Stack direction="row" spacing={1}>
            <TextField
              fullWidth
              autoComplete="gpsnumber"
              type="number"
              label="Number of GPs (FTE)"
              {...getFieldProps("gpsnumber")}
              error={Boolean(touched.gpsnumber && errors.gpsnumber)}
              helperText={touched.gpsnumber && errors.gpsnumber}
            />

            <TextField
              fullWidth
              autoComplete="nursesnumber"
              type="number"
              label="Number of practice nurses (FTE)"
              {...getFieldProps("nursesnumber")}
              error={Boolean(touched.nursesnumber && errors.nursesnumber)}
              helperText={touched.nursesnumber && errors.nursesnumber}
            />
          </Stack>
        </Stack>

        <Stack direction="row" spacing={1} mt={2} alignItems="center">
          <TextField
            fullWidth
            autoComplete="eircode"
            type="text"
            label="Eircode"
            {...getFieldProps("eircode")}
            error={Boolean(touched.eircode && errors.eircode)}
            helperText={touched.eircode && errors.eircode}
          />
          <FormControl fullWidth>
            <InputLabel id="dropdown4-label">Practice place</InputLabel>
            <Select
              labelId="dropdown4-label"
              id="dropdown4"
              value={formik.values.placeid}
              label="Practice place"
              onChange={(e) =>
                formik.setFieldValue("placeid", e.target.value)
              }
              error={Boolean(touched.placeid && errors.placeid)}
              helperText={touched.placeid && errors.placeid}
            >
              {place ? place.map((lab) => <MenuItem value={lab.id}>{lab.name}</MenuItem>) : []}
            </Select>
          </FormControl>
        </Stack>

        <Stack direction="row" spacing={1} mt={2} alignItems="center">
          <FormControl fullWidth>
            <InputLabel id="mainhospitalid-label">
              Main hospital patients referred to
            </InputLabel>
            <Select
              labelId="mainhospitalid-label"
              id="mainhospitalid"
              value={formik.values.mainhospitalid}
              label="Main Hospital patient refered to"
              onChange={(e) =>
                formik.setFieldValue("mainhospitalid", e.target.value)
              }
              error={Boolean(touched.mainhospitalid && errors.mainhospitalid)}
              helperText={touched.mainhospitalid && errors.mainhospitalid}
            >
              {hospitals ? hospitals.map((hosp) => <MenuItem value={hosp.id}>{hosp.name}</MenuItem>) : []}
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <InputLabel id="secondhospitalid-label">
              Second hospital patients referred to
            </InputLabel>
            <Select
              labelId="secondhospitalid-label"
              id="secondhospitalid"
              value={formik.values.secondhospitalid}
              label="Second Hospital patient refered to"
              onChange={(e) =>
                formik.setFieldValue("secondhospitalid", e.target.value)
              }
              error={Boolean(touched.secondhospitalid && errors.secondhospitalid)}
              helperText={touched.secondhospitalid && errors.secondhospitalid}
            >
              {hospitals ? hospitals.map((hosp) => <MenuItem value={hosp.id}>{hosp.name}</MenuItem>) : []}
            </Select>
          </FormControl>
        </Stack>

        <Stack direction="row" spacing={1} mt={2} alignItems="center">
          <FormControl fullWidth>
            <InputLabel id="laboratoryid-label">Main laboratory</InputLabel>
            <Select
              labelId="laboratoryid-label"
              id="laboratoryid"
              value={formik.values.laboratoryid}
              label="Main laboratory"
              onChange={(e) =>
                formik.setFieldValue("laboratoryid", e.target.value)
              }
              error={Boolean(touched.laboratoryid && errors.laboratoryid)}
              helperText={touched.laboratoryid && errors.laboratoryid}
            >
              {labs ? labs.map((lab) => <MenuItem value={lab.id}>{lab.name}</MenuItem>) : []}
            </Select>
          </FormControl>

          <Switch {...getFieldProps("dispensingpractice")} color="primary" />
          <Typography sx={{ color: "text.secondary", fontSize: 15 }}>
            Dispensing practice
          </Typography>
        </Stack>


        <Stack direction="row" spacing={1} mt={2} alignItems="center">
          <FormControl fullWidth>
            <InputLabel id="healthareasid-label">Regional Health Area</InputLabel>
            <Select
              labelId="healthareasid-label"
              id="healthareasid"
              value={formik.values.healthareasid}
              label="Regional Health Area"
              onChange={(e) =>
                formik.setFieldValue("healthareasid", e.target.value)
              }
              error={Boolean(touched.healthareasid && errors.healthareasid)}
              helperText={touched.healthareasid && errors.healthareasid}
            >
              {healthareas ? healthareas.map((lab) => <MenuItem value={lab.id}>{lab.name}</MenuItem>) : []}
            </Select>
          </FormControl>
        </Stack>

        <Stack direction="row" mt={2} alignItems="center">
          <FormControlLabel
            control={
              <Checkbox
                {...getFieldProps("termsAccepted")}
                color="primary"
                value={values.termsAccepted}
              />
            }
          />
          <Typography sx={{ color: "text.secondary", fontSize: 15 }}>
          I confirm that I have read and agree to{" "}
            <Link
              component="a"
              href="https://caranetwork.ie/wp-content/uploads/2023/10/GP-agreement-CARA.pdf"
              underline="hover"
              target="_blank"
              rel="noopener noreferrer"
            >
              GP Agreement
            </Link>
            .
          </Typography>
        </Stack>

        <Stack spacing={1} mt={2}>
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Submit
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}

const mapState = state => ({
  user: state.user,
  options: state.options,
  practice: state.practice
});

const mapDispatch = ({
  practice: { setPractice, getHospitals, getLaboratories, getPlaces, getHealthareas },
  user: { resendOTP } }) => ({
    setPractice: (payload) => setPractice(payload),
    getHospitals: () => getHospitals(),
    getLaboratories: () => getLaboratories(),
    getPlaces: () => getPlaces(),
    getHealthareas: () => getHealthareas(),
    resendOTP: (payload) => resendOTP(payload),
  });

export default connect(mapState, mapDispatch)(RegistrationDetailForm);