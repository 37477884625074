
let initial_state = {
    gender: [],
    age: [],
    gms: [],
    healtharea: [],
    gps: [],
    patientnumber: [],
    locationarea: [],
    dateStart: new Date('2017-01-01'),
    dateEnd: new Date()
};
export const filters = {
    state: initial_state,

    reducers: {
        updateFilter(state, payload) {
            console.log("Filters :", payload);
            return {
                ...state,
                [payload.key]: payload.value
            }
        },

        updateDateFilter(state, payload) {
            return {
                ...state,
                dateStart: payload.lastYear,
                dateEnd: payload.currentYear
            }
        },

        resetFilters(state, payload) {
            return initial_state
        },

        resetSelections(state, payload) {
            return {
                ...state,
                healtharea: [],
                gps: [],
                patientnumber: [],
                locationarea: [],
            }
        }
    },
    effects: (dispatch) => ({
        async updateDateFilters(payload, state) {

            try {
                let lastYear = state.user.previousYear;
                let currentYear = state.user.lastUpload;
              return await this.updateDateFilter({lastYear: lastYear,currentYear: currentYear}); 
            } catch (error) {
              return error;
            }
          },
    })
};
