// material
import { Box, Grid, Container, Typography, Paper, Stack, Button } from '@mui/material';
// components
import Page from '../components/Page';
import {
  AppConsultTotalCompare,
  AppActivePatientsCompare,
  AppPrescriptionsTotalCompare,
  AppPractice1Compare,
  AppPractice2Compare,
  Appmale_femaleDonutCompare,
  Appmale_femaleBarCompare,
  AppAgesDonutCompare,//
  AppAgesBarCompare, //
  AppTypesDonutCompare, //
  AppTypesBarCompare,
  AppConsultationsLineCompare,
  AppConsultationsBar1Compare,
} from '../components/_dashboard/practiceOverviewComparisons';
import ComparisonsFilters from '../components/_dashboard/comparisonsfilters';
import ReactGA from "react-ga4";
import { connect } from "react-redux";
import { useState, useEffect } from "react";


// ----------------------------------------------------------------------



function ProductList(props) {
  
  // useEffect(() => {
  //   const dashNo = props.user.dashboard;
  //   const eventCategory = "PracticeCompairView "+dashNo
  //   const eventPage = "Practice Compair Dashboard "+dashNo;
  
  
  //   ReactGA.send({
  //     hitType: "event",  // Instead of "pageview", we use "event" for custom tracking
  //     eventCategory: eventCategory,  // Define your own category
  //     eventAction: eventCategory,   // Or "pageview2" depending on your needs
  //     eventLabel: window.location.pathname,  // The current path
  //     title: eventPage,  // Keep the title if necessary
  //   });
  // }, []);

return (
    <Page title="CARA | Practice Comparision Dashboard"  sx={{backgroundColor: '#fff'}}>
      <Container maxWidth="xl" >
       <Box sx={{ pt: 10 }}>
         
        </Box>
        <ComparisonsFilters />

        <Grid container spacing={12}>       
          <Grid item xs={12} sm={6} md={12}>
          <Typography variant="h2" color='secondary' style={{marginTop:'40px'}}>Practice Overview</Typography>
          <Typography variant="subtitle1" color='secondary'>Graphs display data for 12 months previous to the last upload.</Typography>
          <br />
          <Button variant="contained" href=''>Upload new data</Button>
          <br></br>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <AppPrescriptionsTotalCompare />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
          <AppActivePatientsCompare />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <AppConsultTotalCompare />
          </Grid>        

          </Grid>
        <Grid container spacing={4} sx={{ borderRadius: 0}}>


          <Grid item xs={12} md={6} lg={12} id="prescriptions">
          <br />
          <br />
          <Typography variant="h4" color='#22577A'>Prescriptions</Typography>
          </Grid>
          </Grid>
          <Grid container spacing={8}>
          <Grid item xs={12} md={6} lg={12}>
            <AppPractice1Compare />
          </Grid>

          <Grid item xs={12} md={6} lg={12}>
            <AppPractice2Compare />
          </Grid>

          <Grid item xs={12} md={6} lg={12} id="demographics">
          <br />
          <br />
          <Typography variant="h4" color='#22577A' >Patients</Typography>
          </Grid>

          <Grid item xs={12} md={6} lg={12}>
          <Typography variant="h6" color='#22577A'>Gender</Typography>
          </Grid>

          <Grid item xs={12} md={6} lg={6}>
            <Appmale_femaleDonutCompare />
          </Grid>

          <Grid item xs={12} md={6} lg={6}>
            <Appmale_femaleBarCompare />
          </Grid>

          <Grid item xs={12} md={6} lg={12}>
          <Typography variant="h6" color='#22577A'>Age</Typography>
          </Grid>

          <Grid item xs={12} md={6} lg={12}>
            <AppAgesDonutCompare />
          </Grid>

          <Grid item xs={12} md={6} lg={12}>
            <AppAgesBarCompare />
          </Grid>

          <Grid item xs={12} md={6} lg={12}>
          <Typography variant="h6" color='#22577A'>Type</Typography>
          </Grid>

          <Grid item xs={12} md={6} lg={5}>
            <AppTypesDonutCompare />
          </Grid>

          <Grid item xs={12} md={6} lg={7}>
            <AppTypesBarCompare />
          </Grid>

          <Grid item xs={12} md={6} lg={12} id="consultations">
          <br />
          <br />
          <Typography variant="h4" color='#22577A'>Consultations</Typography>
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <AppConsultationsLineCompare />
          </Grid>

          <Grid item xs={12} md={6} lg={12}>
          <Typography variant="h6" color='#22577A'>
          Consultations for Chronic Diseases (ICPC)</Typography>
            <AppConsultationsBar1Compare />
          </Grid>

          </Grid>
      </Container>
    </Page>
  );
}

const mapState = (state) => ({
  user: state.user,
});

const mapDispatch = ({
}) => ({
});

export default connect(mapState, mapDispatch)(ProductList);