// scroll bar
import 'simplebar/src/simplebar.css';

import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';

import { init } from '@rematch/core';
import { Provider } from 'react-redux';
import models from './models/index.js';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import logger from 'redux-logger';

// ----------------------------------------------------------------------

// Redux-Persist configuration
const persistConfig = {
  key: 'root',
  storage,
};

const store = init({
  models,
  redux: {
    middlewares: [logger],
  },
});

const persistedReducer = persistReducer(persistConfig, store.reducer);

const persistedStore = {
  ...store,
  replaceReducer: (nextReducer) => store.replaceReducer(nextReducer),
  reducer: persistedReducer,
};

const persistor = persistStore(persistedStore);

// Custom loading spinner
const LoadingSpinner = () => (
  <div className="loading-spinner">
    Loading...
  </div>
);
persistor.subscribe(() => {
  console.log('Persistor state:', persistor.getState());
});


ReactDOM.render(
  <Provider store={persistedStore}>
    {/* <PersistGate loading={<LoadingSpinner />} persistor={persistor} timeout={5000}> */}
      <HelmetProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </HelmetProvider>
    {/* </PersistGate> */}
  </Provider>,
  document.getElementById('root')
);

// If you want to enable client cache, register instead.
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
