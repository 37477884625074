import { merge } from "lodash";
import ReactApexChart from "react-apexcharts";
// material
import { useTheme, styled } from "@mui/material/styles";
import { Card, CardHeader, Stack, Box, Typography, Grid } from "@mui/material";
// utils
import { fNumber } from "../../../utils/formatNumber";
//
import { BaseOptionChart } from "../../charts";

// ----------------------------------------------------------------------

function AppCurrentVisits(props) {
  const CHART_DATA_Consultations = [
    {
      data: [
        {
          x: "All consultations",
          y: props.consultations.all,
        },
        {
          x: "Consultations with antibiotic",
          y: props.consultations.ab,
        },
      ],
    },
  ];

  const CHART_DATA_Prescriptions = [
    {
      data: [
        {
          x: "All prescriptions",
          y: props.prescriptions.all,
        },
        {
          x: "Antibiotics",
          y: props.prescriptions.ab,
        },
      ],
    },
  ];

  const chartOptions_Consultations = merge(BaseOptionChart(), {
    tooltip: {
      marker: { show: false },
      x: {
        show: true,
        // format: 'dd MMM',
        formatter: undefined,
        title: {
          formatter: (seriesName) => seriesName,
        },
      },
      y: {
        formatter: function (value, opts) {
          const percentage =
            ((value / opts.series[0][0]) * 100).toFixed(0) + "%";
          return percentage;

          // if(opts.seriesIndex == 0){
          //   const sum = opts.series[0].reduce((a, b) => b, 0);
          //   const percent = (value / sum) * 100;
          //   return percent.toFixed(0) + '%'
          // }
          // if(opts.seriesIndex == 1){
          //   const sum = opts.series[1].reduce((a, b) => a + b, 0);
          //   const percent = (value / sum) * 100;
          //   return percent.toFixed(0) + '%'
          // }
        },
        title: {
          formatter: (seriesName, opts) => {
            return `Percentage of consultations: `;
          },
        },
      },
    },
    grid: {
      borderColor: "transparent",
      row: {
        colors: ["transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    colors: ["#3E8397"],
    plotOptions: {
      bar: {
        horizontal: false,
        dataLabels: {
          position: "top",
        },
        columnWidth: "50%",
        distributed: false,
        borderRadiusApplication: "end",
      },
    },
    legend: {
      show: false,
    },
    //labels should show total number
    dataLabels: {
      enabled: true,
      style: {
        colors: ["#708090"],
        marginBottom: "10px",
      },
      offsetY: -18,
      formatter: function (val, opt) {
        return fNumber(val);
      },
    },
    yaxis: {
      show: false,
      min: 0,
      max: props.consultations.all * 1.01,
    },
    stroke: {
      show: false,
    },
  });
  const chartOptions_Prescribe = merge(BaseOptionChart(), {
    tooltip: {
      marker: { show: false },
      x: {
        show: true,
        // format: 'dd MMM',
        formatter: undefined,
        title: {
          formatter: (seriesName) => seriesName,
        },
      },
      y: {
        formatter: function (value, opts) {
          const percentage =
            ((value / opts.series[0][0]) * 100).toFixed(0) + "%";
          return percentage;
        },
        title: {
          formatter: (seriesName, opts) => {
            return `Percentage of prescriptions: `;
          },
        },
      },
    },
    grid: {
      borderColor: "transparent",
      row: {
        colors: ["transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    colors: ["#3E8397"],
    plotOptions: {
      bar: {
        horizontal: false,
        dataLabels: {
          position: "top",
        },
        columnWidth: "50%",
        distributed: false,
        borderRadiusApplication: "end",
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: true,
      style: {
        colors: ["#708090"],
      },
      offsetY: -18,
      formatter: function (val, opt) {
        return fNumber(val);
      },
    },
    yaxis: {
      show: false,
      min: 0,
      max: props.prescriptions.all * 1.01,
    },
    stroke: {
      show: false,
    },
  });

  return (
    <Card sx={{ boxShadow: "none" }}>
      <Typography variant="h4" mt={10} color="#22577A">
        Antibiotic consultations and prescriptions in the selected duration.
      </Typography>
      <br />
      <br />
      <Stack
        direction="row"
        justifyContent="space-evenly"
        alignItems="flex-end"
        spacing={6}
        mt={1}
      >
        <Grid container justifyContent="space-around">
          <Grid item sm={12} md={6} lg={6} xl={5}>
            <Box dir="ltr">
              <ReactApexChart
                type="bar"
                series={CHART_DATA_Consultations}
                options={chartOptions_Consultations}
                height={480}
              />
            </Box>
          </Grid>
          <Grid item sm={12} md={6} lg={6} xl={5}>
            <Box sx={{ mx: 1 }} dir="ltr">
              <ReactApexChart
                type="bar"
                series={CHART_DATA_Prescriptions}
                options={chartOptions_Prescribe}
                height={480}
              />
            </Box>
          </Grid>
        </Grid>
      </Stack>
    </Card>
  );
}

import { connect } from "react-redux";
const mapState = (state) => ({
  consultations: state.antibiotics.consultations,
  prescriptions: state.antibiotics.prescriptions,
});

const mapDispatch = ({ antibiotics: { getGeneralAB } }) => ({
  getGeneralAB: () => getGeneralAB(),
});

export default connect(mapState, mapDispatch)(AppCurrentVisits);
