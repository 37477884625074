import { Icon } from "@iconify/react";
import androidFilled from "@iconify/icons-ant-design/android-filled";
// material
import { alpha, styled } from "@mui/material/styles";
import { Button, Box, Card, Typography } from "@mui/material";
// utils
import { fNumber, fShortenNumber } from "../../../utils/formatNumber";
import checkOutlined from "@iconify/icons-ant-design/check-outlined";

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: "none",
  textAlign: "center",
  padding: theme.spacing(2, 2),
  color: "#22577A",
  backgroundColor: "#ffffff",
  borderColor: theme.palette.primary.main,
  borderWidth: 5,
  height: 250,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
}));

// ----------------------------------------------------------------------
function AppWeeklySales(props) {
  return (
    <RootStyle>
      <Box
        sx={{
          height: 250,
          border: 1,
          borderRadius: 2,
          borderColor: "secondary",
        }}
      >
        <br />
        <Typography variant="h5" mt={1.5} color="#aa3377">
          {fNumber(props.generalAB.total)}
        </Typography>
        <Typography variant="subtitle2">
          Number of antibiotics prescribed
        </Typography>
          {" "}
          <br></br>
        <Typography variant="subtitle2" color="#fff">
          {" "}
          <br></br>
          {" "}
        </Typography>
        <Typography variant="subtitle2">Filtered practices average:</Typography>
        <Typography variant="h5" color="#aa3377">
          {fNumber(props.generalABcomparison.othertotal)}
        </Typography>
        <br></br>
        <Button
          fullWidth
          style={{ whiteSpace: "nowrap" }}
          variant="contained"
          color="secondary"
          href="#antibiotics"
        >
          See more on antibiotics
        </Button>
      </Box>
    </RootStyle>
  );
}

import { connect } from "react-redux";
const mapState = (state) => ({
  generalAB: state.antibiotics.generalAB,
  generalABcomparison: state.antibioticscomparisons.generalABcomparison,
});

const mapDispatch = ({
  antibioticscomparisons: { getComparisonsAB },
  antibiotics: { getGeneralAB },
}) => ({
  getComparisonsAB: () => getComparisonsAB(),
  getGeneralAB: () => getGeneralAB(),
});

export default connect(mapState, mapDispatch)(AppWeeklySales);
